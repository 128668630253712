/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';
import axios from 'axios';
import op from 'object-path';

import Template from 'appdir/components/Template';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import Button from 'appdir/components/common-ui/Button';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MyWimbledonTitle from 'appdir/components/common-ui/MyWimbledonTitle';

import { getQuerystringValues } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component: MyWimbledonUnsubscribe
 *
 *
 * --- States ---
 *  isValid ---------- checks email format if it's in the valid email format
 *  unsubscribepath -- path to endpoint (send uid and email params)
 *  isSubmitting ----- when onClick or onSubmit is triggered set to true to send form data, avoid double submission (true|false)
 *  status ------------ submission status ('success'|'error')
 *
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['MyWimbledonUnsubscribe'],
		unsubscribePath: op.get(state['Config'].myWimbledon, 'api.unsubscribe', null),
		loggedIn: state['Gigya']['loggedIn'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	getAccountInfo: params => dispatch(deps.actions.Gigya.getAccountInfo(params)),
});

class MyWimbledonUnsubscribe extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props);
		this.formSubmitted = false;
	}

	componentDidMount() {
		let search = this.props.location.search.replace(/^\?/, '');
		let parsedQs = getQuerystringValues(search);

		//2022, stop requiring email so isValid is always true

		this.setState({
			parsedQs,
			isSubmitting: false,
			isValid: true,
		});

		logger.log('[MyWimbledonUnsubscribe] componentDidMount this.props:%o, parsedQs:%o', this.props, parsedQs);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[MyWimbledonUnsubscribe] componentDidUpdate state:%o', this.state);

		let uid = op.get(this.state, 'parsedQs.uid', '');

		if (
			this.state.isValid &&
			this.state.unsubscribePath &&
			this.state.isSubmitting &&
			!this.formSubmitted &&
			this.state.status !== 'error'
		) {
			let url = this.state.unsubscribePath
				.replace('<uid>', encodeURIComponent(uid))
				.replace('<email>', encodeURIComponent(this.state.email));

			logger.log(
				'[MyWimbledonUnsubscribe] componentDidUpdate fetching url: %o, uid: %o, email:%o',
				this.state.unsubscribePath,
				uid,
				this.state.email
			);

			let request = axios({
				method: 'post',
				url: url,
				headers: { 'Content-type': 'application/json' },
			})
				.then(res => {
					if (res.status == 200 && (res.data.success || res.data.success == 'true')) {
						this.setState(prevState => {
							this.formSubmitted = true;
							this.updateUser();

							return {
								...prevState,
								unsubscribe: res.data,
								errorMsg: 'success',
								status: 'success',
								isSubmitting: false,
							};
						});
					} else {
						this.setState(prevState => {
							return {
								...prevState,
								unsubscribe: res.data,
								errorMsg: '',
								status: 'success',
								isSubmitting: false,
							};
						});
						// don't show error for security
						// this.setState({
						// 	errorMsg: 'Invalid UID or Email address. Please try again.',
						// 	status: 'error',
						// 	isSubmitting: false
						// })
					}
					logger.log('[MyWimbledonUnsubscribe] success fetching data: %o', res);
				})
				.catch(error => {
					logger.error('[MyWimbledonUnsubscribe] error fetching unsubscribe endpoint data: %o', error);
					this.setState(prevState => {
						return {
							...prevState,
							unsubscribe: error,
							errorMsg: '',
							status: 'success',
							isSubmitting: false,
						};
					});
					// don't show error for security
					// this.setState({
					// 	errorMsg: 'Invalid UID or Email address. Please try again.',
					// 	status: 'error',
					// 	isSubmitting: false
					// })
				});
		}

		if (prevState) {
			let pre_uid = op.get(prevState, 'parsedQs.uid', '');

			// if either UID or email is different from previous state, enable fetch by resetting status and clear out the errorMsg
			if ((prevState.status == 'error' && prevState.email !== this.state.email) || pre_uid !== uid) {
				this.setState({
					errorMsg: '',
					status: '',
				});
			}
		}
	}

	handleUnsubscribe = event => {
		//event.preventDefault();
		this.setState({
			isSubmitting: true,
		});
	};

	handleEmailChange = e => {
		this.setState({
			email: e.target.value,
			isValid: this.validateEmail(e.target.value),
		});
	};

	validateEmail(email) {
		let format = /\S+@\S+\.\S+/;
		return format.test(email);
	}

	updateUser() {
		//logger.log('[MyWimbledonUnsubscribe] updateUser - loggedIn:%o', this.state.loggedIn);
		if (this.state.loggedIn) {
			logger.log('[MyWimbledonUnsubscribe] updateUser');
			this.props.getAccountInfo({ src: 'unsubscribe' });
		}
	}

	render() {
		//logger.log('[MyWimbledonUnsubscribe] render');

		let header_propsData = {
			headerType: 'mywimbledon',
			shortTitle: 'Unsubscribe',
			scrollElem: '.content-main',
			metaTitle: 'Unsubscribe',
			metaDescription: 'myWimbledon - Wimbledon ' + values.tournamentYear,
			metaDate: '',
			metaPlayers: '',
			imageSrc: '/assets/images/headers/header_unsubscribe.jpg',
			scroll: true,
		};

		let emailVal = op.get(this.state, 'email', '');
		let errorMsg = op.get(this.state, 'errorMsg', '');
		let isValid = op.get(this.state, 'isValid', false);

		if (this.state.unsubscribePath) {
			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main content">
						<div className="content--header-wrapper">
							<div className="news--header">
								<div className="news--header-title">
									<h1>{header_propsData.shortTitle}</h1>
								</div>
								<div className="news--header-shorttitle">
									<h1>{header_propsData.shortTitle}</h1>
								</div>
							</div>
						</div>

						<div className="column-layout content">
							{this.state.status !== 'success' ? (
								<Fragment>
									<div className="two-col margin  center">
										<h4>We would be delighted if you stayed.</h4>
										<p>We’re sorry to see you go, however we respect your decision to unsubscribe.</p>
										<p>If you wish to unsubscribe from all <MyWimbledonTitle /> marketing emails, please click on the button below.</p>
									</div>
									<div className="two-col margin center">
										<Button className={`btn solid ballot`} onClick={this.handleUnsubscribe}>
											Unsubscribe
										</Button>
									</div>
									<div className="two-col margin  center">
										<p>Please note that even if you unsubscribe, you may still receive relevant service messages from us, such as order or shipping confirmations.</p>
										<p>If you would prefer to receive fewer emails or only receive updates on specific topics instead, you can manage your preferences and select the communications you would like from us by clicking the button below.</p>
									</div>
									<div className="two-col margin center">
										<Button className={`btn solid ballot`} to={'/en_GB/mywimbledon/consent.html'}>
											Manage your preferences
										</Button>
									</div>
								</Fragment>
							) : (
								<div className="two-col margin">
									<h4>YOU HAVE SUCCESSFULLY UNSUBSCRIBED.</h4>
									<p>Please be aware that it may take up to 24 hours for this change to take effect.</p>
									<p>You may still receive relevant service messages from us, such as order or shipping confirmations.</p>
								</div>
							)}
						</div>
					</div>
					<Footer />
				</Template>
			);
		} else {
			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main">
						<LoadingIndicator />
					</div>
					<Footer />
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MyWimbledonUnsubscribe);
