import React from 'react';
import Debenture from 'appdir/components/cms/Debenture';
import DebenturePrivate from 'appdir/components/cms/DebenturePrivate';
import RegisterButton from 'appdir/components/cms/RegisterButton';
import GenericButton from 'appdir/components/cms/GenericButton';
import RestaurantBooking from 'appdir/components/cms/RestaurantBooking';
import Collapsible from 'appdir/components/cms/CollapsibleComponent';
import TabNavigation from 'appdir/components/cms/TabNavigation';
import Draw from 'appdir/components/cms/Draw';
import HeadToHead from 'appdir/components/cms/HeadToHead';
import OrderOfPlay from 'appdir/components/cms/OrderOfPlay';
import MyWPromo from 'appdir/components/cms/MyWPromo';
import ContentLink from 'appdir/components/cms/ContentLink';

const CMS = ({ currentUser }) => {
	logger.log('[CMS] currentUser:%o', currentUser);

	return (
		<div className="content-main column-layout news article ">
			<div className="four-col ">
				<strong>TabNavigation</strong>
			</div>
			<div className="four-col ">
				<TabNavigation data={{ key: 'playerLanding' }} />
			</div>
			<div className="four-col ">
				<TabNavigation data={{ key: 'memberBookings' }} />
			</div>
			<div className="four-col ">
				<strong>{`MyWPromo (only displays if not logged in)`}</strong>
			</div>
			<div className="four-col ">
				<MyWPromo />
			</div>
			<div className="four-col ">
				<strong>Collapsible</strong>
			</div>
			<div className="four-col ">
				<Collapsible
					data={{ style: 'test', type: null, title: 'Ballot Information', text: '<h1>Test part two</h1>' }}
				/>
			</div>
			<div className="four-col ">
				<strong>Draw</strong>
			</div>
			<div className="four-col ">
				Errors
				{/* <Draw/> */}
			</div>
			<div className="four-col ">
				<strong>HeadToHead</strong>
			</div>
			<div className="four-col ">
				<HeadToHead style="four-col" player1="atpa0e2" player2="atpd643" />
			</div>
			<div className="four-col ">
				<strong>OrderOfPlay</strong>
			</div>
			<div className="four-col ">
				Errors
				{/* <OrderOfPlay style="two-col" dayId="8" courtId="A" /> */}
			</div>

			<div className="four-col ">
				<strong>ContentLink, default and with date</strong>
			</div>
		
				<ContentLink data={{
					style: 'one-col',
					title: "Test Item",
					text: "Grand Slam® Player Development Programme announces 2024 Player Grant Recipients'",
					images: {
						image: {
							url: "https://photo-assets-cdt.wimbledon.com/images/pics/thumbs/m_tl106615.jpg",
						},
					},
					url: '/en_GB/news/articles/2024-04-11/2024-04-11_grand_slam_player_development_programme_announces_2024_player_grant_recipients.html',
				}} />
				<ContentLink data={{
					style: 'one-col',
					layout: "date",
					date: "1712855099000",
					text: "Grand Slam® Player Development Programme announces 2024 Player Grant Recipients'",
					description: "Grand Slam Player Grants issued to aid the development of fifty-one players",
					images: {
						image: {
							url: "https://photo-assets-cdt.wimbledon.com/images/pics/thumbs/m_tl106615.jpg",
						},
					},
					url: '/en_GB/news/articles/2024-04-11/2024-04-11_grand_slam_player_development_programme_announces_2024_player_grant_recipients.html',
				}} />


			<div className="four-col ">Debenture Register Button</div>
			<RegisterButton data={{ style: 'four-col', registrationType: 'debenture_register' }} />

			<div className="four-col ">Debenture Application Button</div>
			<RegisterButton data={{ style: 'four-col', registrationType: 'debenture_application' }} />
		</div>

		// <div className="content-main column-layout news article ">
		// 	<div className="two-col margin">
		// 		<Debenture />
		// 		<DebenturePrivate />
		// 	</div>

		// 	<div className="two-col ">myW Register Button</div>
		// 	<RegisterButton data={{ style: 'four-col' }} />

		

		// 	<div className="two-col ">Generic Button</div>
		// 	<GenericButton data={{ style: 'four-col', text: 'test button' }} />
	);
};

export default CMS;
