import React, { Component, Fragment } from 'react';
import { RoleRegistrationInviteContext } from '../context';
import op from 'object-path';
import isEmpty from 'lodash/isEmpty';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { FormInput, FormCheckBox, FormSelect } from 'appdir/components/common-ui/FormFields';
import SectionDivider from 'appdir/components/cms/SectionDivider';
import Button from 'appdir/components/common-ui/Button';
import WimLink from 'appdir/components/general/WimLink';
import MyWimbledonTitle from 'appdir/components/common-ui/MyWimbledonTitle';

class DebProspect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
		};

		this.handleSubmit = this.handleSubmit.bind(this);

		this.initialValues = {
			debComm: false,
		};

		logger.log(
			'[RoleRegistrationInvite - DebProspect] constructor - state:%o context:%o',
			this.state,
			this.context
		);
	}

	componentDidMount() {
		logger.log(
			'[RoleRegistrationInvite - DebProspect] componentDidMount - state:%o context:%o',
			this.state,
			this.context
		);

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Role Registration Invite Debenture',
		});
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log(
			'[RoleRegistrationInvite - DebProspect] componentDidUpdate - state:%o context:%o',
			this.state,
			this.context
		);
	}

	/**
	 * define the yup schema for form validation
	 * @param {*} content
	 */
	getSchema(content) {
		let schema = {
			debComm: yup
				.boolean()
				.required('You must agree to Debenture communication to register.')
				.oneOf([true], 'You must agree to Debenture communication to register.'),
			hearAbout: yup.string().required('Please choose how you heard about Debentures.'),
		};

		return yup.object(schema);
	}

	/**
	 * form submit handle, calls addRole in index to submit to orch
	 * @param {*} values
	 * @param {*} actions
	 */
	handleSubmit(values, actions) {
		//logger.log('[RoleRegistrationInvite - DebProspect] handleSubmit - values:%o', values);
		this.addRole(values.hearAbout);
	}

	addRole(value) {
		value = `how_i_heard_about_the_issue:${value}`;
		let issueTag = `issue:${this.props.issue}`;
		if (this.context.addRole) {
			let tags = {
				debentures: {
					email: {
						tags: [value, issueTag],
					},
				},
			};
			logger.log('[RoleRegistrationInvite - DebProspect] addRole - tags:%o', tags);
			this.context.addRole(tags);
		}
	}

	render() {
		logger.log('[RoleRegistrationInvite - DebProspect] render - state:%o status:%o', this.state, this.state.status);

		let validationSchema = this.getSchema();

		return (
			<Fragment>
				{this.context.complete ? (
					<Fragment>
						<div className="four-col body">
							Thank you for registering your interest in future debenture issues.
						</div>
						<div className="four-col body">
							Please visit <MyWimbledonTitle link="true" /> to access your profile and more.
						</div>
						<div className="four-col body">
							You can amend your preferences at any time within 'Consents & Preferences' accessed via your{' '}
							<MyWimbledonTitle link="true" /> profile.
						</div>
					</Fragment>
				) : this.context.hasRole ? (
					<Fragment>
						<div className="four-col body">
							Our records indicate you have already submitted your preference to receive information on
							future debenture issues.
						</div>
						<div className="four-col body">
							You can amend your preferences at any time within 'Consents & Preferences' accessed via your{' '}
							<MyWimbledonTitle link="true" /> profile.
						</div>
					</Fragment>
				) : !this.context.isValid ? (
					<Fragment>
						<div className="two-col margin-col">
							Unfortunately your registration code is invalid or has expired. Please try again.
						</div>
						<div className="two-col margin-col">
							If the problem persists <WimLink to="/en_GB/contact/index.html">contact us</WimLink>.
						</div>
						<div className="two-col margin-col oidc_message">Message: {`${this.context.status}`}</div>
					</Fragment>
				) : (
					<Formik
						initialValues={this.initialValues}
						validationSchema={validationSchema}
						onSubmit={this.handleSubmit}
						enableReinitialize={true}>
						{formProps => {
							return (
								<Fragment>
									<div className="four-col heading">Confirm your Preference</div>
									<div className="four-col body">
										Complete the final step to request receiving information about future debenture
										issues.
									</div>
									<SectionDivider data={{ style: 'four-col', dividerStyle: 'light' }} />
									<div className="two-col margin">
										<FormSelect
											label="How did you hear about debentures?"
											name="hearAbout"
											disabled={false}
											value={formProps.values.hearAbout || ''}
											required>
											<option value="">-- Options --</option>
											{
												Object.entries(this.props.heardOptions).map(item => {
													logger.log('[RoleRegistrationInvite - DebProspect] heardOptions:%o', item);
													return (
														<option value={item[0]}>{item[1]}</option>
													)
												})
											}
										</FormSelect>
										<br />
										<br />
									</div>
									<div className="two-col margin">
										<FormCheckBox name="debComm" id="debComm">
											<div className="label-text required">
												{' '}
												By checking this box you confirm that you are happy to receive news and
												information from The All England Lawn Tennis Club regarding the Club,
												the Championships and the Grounds via email and phone. You can amend
												your preferences at any time within your 'Consents & Preferences'
												accessed via your myWIMBLEDON profile.
											</div>
										</FormCheckBox>
									</div>
									<div className="four-col body">
										<Button className={`btn solid`} onClick={formProps.submitForm}>
											CONFIRM
										</Button>
									</div>
								</Fragment>
							);
						}}
					</Formik>
				)}
			</Fragment>
		);
	}
}
DebProspect.contextType = RoleRegistrationInviteContext;
export default DebProspect;
