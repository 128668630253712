import React, { Component } from 'react';
import { MyWimContext } from './context';
import MeasurementUtils from 'appdir/lib/analytics';
import FormProfile from './elements/FormProfile';
import Debentures from './elements/Debentures';
import Amex from './elements/Amex';
import SimpleComponent from 'appdir/components/cms/SimpleComponent';
import Collapsible from 'react-collapsible';
import axios from 'axios';
import op from 'object-path';
import { BALLOT_STATUS } from 'appdir/components/general/Util';

class MyWimProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.fetchedDebenture = false;

		//logger.log('[MyWimProfile] constructor - state:%o context:%o', this.state, this.context);
	}
	componentDidMount() {
		logger.log('[MyWimProfile] componentDidMount - state:%o context:%o', this.state, this.context);

		this.updateState();
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[MyWimProfile] componentDidUpdate - state:%o context:%o', this.state, this.context);

		this.updateState(prevState);

		let userRoles = op.get(this.context, 'currentUser.roles', []);

		if (userRoles.length > 0) {
			// get debenture data
			let debentureRole = userRoles.filter(d => d.roleId == 1000020)[0];
			let haslinkId = debentureRole ? debentureRole.linkId != null : false;
			logger.log('[MyWimProfile] componentDidUpdate - haslinkId:%o debentureRole:%o', haslinkId, debentureRole);

			if (debentureRole && haslinkId && !this.fetchedDebenture) {
				//logger.log("[MyWimProfile] componentDidUpdate - deb api:%o", this.state.data.api.debenture.debenture);
				this.fetchedDebenture = true;

				this.context
					.getJWT()
					.then(token => {
						logger.log('[MyWimProfile] componentDidUpdate - token:%o', token.jwt);
						this.getDebenture(token.jwt.id_token);
					})
					.catch(error => {
						logger.error('[MyWimProfile] componentDidUpdate - error getting token');
					});
			}

			//get ballot roles
			let ballotRole = userRoles.filter(d => d.roleId == 1000060)[0];
			if (!this.state.ballot && ballotRole) {
				this.setState({
					ballot: ballotRole,
				});
			}

			//get amex role
			let amexRole = userRoles.filter(d => d.roleId == 1000230)[0];
			if (!this.state.amex && amexRole) {
				this.setState({
					amex: amexRole,
				});
			}
		}
	}

	getDebenture(jwt_token) {
		let request = axios({
			method: 'post',
			url: this.context.api.debenture.debenture,
			data: {
				jwtToken: jwt_token,
			},
			headers: { 'Content-type': 'application/json' },
		})
			.then(res => {
				logger.log('[MyWimProfile] success fetching data: %o', res);
				//check for valid data
				if (res.data.data) {
					this.setState({
						debenture: res.data.data,
					});
				}
			})
			.catch(error => {
				logger.error('[MyWimProfile] error fetching debenture data: %o', error);
			});
	}

	updateState(prevState) {
		let siteProvider = op.get(this.context, 'currentUser.loginProvider', false);
		let email = op.get(this.context, 'currentUser.profile.email', '');
		let prevEmail = op.get(prevState, 'profileData.email', '');
		let address = op.get(this.context, 'currentUser.profile.address', '');
		let prevAddress = op.get(prevState, 'profileData.address', '');

		logger.log('[MyWimProfile] updateState siteProvider:%o prev:%o email:%o', siteProvider, prevEmail, email);

		if (siteProvider && (email != prevEmail || address != prevAddress)) {
			this.setState({
				profileData: {
					...this.context.currentUser.profile,
					...this.context.currentUser.data.profile,
					loginProvider: this.context.currentUser.loginProvider,
				},
			});
		}
	}

	onAccordionOpen(child) {
		//logger.log("[MyWimProfile] onAccordionOpen - child:%o", child);
		MeasurementUtils.dispatchMeasurementCall('myWprofileAccordion', child);
	}

	render() {
		let transitionTime = 150;

		//indicates if ballot role has status oc ticketholder
		let ticketHolder = op.get(this.state, 'ballot.status', '') == BALLOT_STATUS.TICKETHOLDER;
		// indicates if user has approved Ballot role
		let ballotReg = op.get(this.state, 'ballot.approved', false);
		//assing the ballot period or on/off status
		let ballotPeriod = this.context.ballotData.period;

		logger.log('[MyWimProfile] render - state:%o context:%o', this.state, this.context);
		logger.log(
			'[MyWimProfile] render - period:%o ballotReg:%o ticketHolder:%o',
			ballotPeriod,
			ballotReg,
			ticketHolder
		);

		let firstOpen,
			secondOpen,
			thirdOpen,
			fourthOpen = false;
		if (this.state.ballot) {
			firstOpen = true;
		} else if (this.state.ticketholder) {
			secondOpen = true;
		} else if (this.state.debenture) {
			thirdOpen = true;
		} else if (this.state.amex) {
			fourthOpen = true;
		}

		return this.state.profileData ? (
			<div className="my-wimbledon-page">
				<div className="profile-container">
					<div className="profile-title title-header">Account Information</div>
					<FormProfile
						profile={this.state.profileData}
						windowSize={this.context.windowSize}
						lockRoles={this.context.lockRoles}
					/>
				</div>
				{ballotPeriod != 'pre' ? (
					<Collapsible
						trigger="AELTC PUBLIC BALLOT INFORMATION"
						transitionTime={transitionTime}
						key={'ballot'}
						open={firstOpen}>
						<SimpleComponent tag="ballotStatus" style="two-col margin collapsible"></SimpleComponent>
					</Collapsible>
				) : null}
				{this.state.debenture ? (
					<Collapsible
						trigger="Debenture Information"
						transitionTime={transitionTime}
						key={'debentures'}
						open={thirdOpen}>
						<Debentures data={this.state.debenture} version={this.context.version} />
					</Collapsible>
				) : null}
				{this.state.amex ? (
					<Collapsible
						trigger="amex card holder"
						transitionTime={transitionTime}
						key={'amex'}
						open={fourthOpen}>
						<Amex data={this.state.amex} version={this.context.version} />
					</Collapsible>
				) : null}
			</div>
		) : null;
	}
}
MyWimProfile.contextType = MyWimContext;
export default MyWimProfile;
